import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

const NotFoundCatalog = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col flex-1 items-center justify-center h-screen">
      <Image
        width={196}
        height={196}
        src={'/catalog-not-found.png'}
        alt=""
        className="rounded-full"
      />
      <p className="text-gray-500">{t('catalog.notFound')}</p>
    </div>
  );
};

export default NotFoundCatalog;
