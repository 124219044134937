import React, { useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const sortOptions = ['newest', 'highlighted', 'price-asc', 'price-desc'];

// eslint-disable-next-line react/prop-types
const Sort = ({ onSortChange }) => {
  const { t } = useTranslation();
  const [sort, setSort] = useState('newest');

  useEffect(() => {
    onSortChange(sort);
  }, [sort]);

  return (
    <Menu as="div" className="relative inline-block text-right ml-4">
      <div>
        <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 items-center">
          {t(`gridSort.${sort}`)}
          <ChevronDownIcon
            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
          <div className="py-1">
            {sortOptions.map((option) => (
              <Menu.Item key={option}>
                {({ active }) => (
                  <a
                    onClick={() => setSort(option)}
                    className={classNames(
                      sort === option
                        ? 'font-medium text-gray-900'
                        : 'text-gray-500',
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm',
                    )}
                  >
                    {t(`gridSort.${option}`)}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default Sort;
