import { useState } from 'react';

const useCategory = () => {
  const [categories, setCategories] = useState([]);

  return {
    categories,
    setCategories,
  };
};

export default useCategory;
