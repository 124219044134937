import React from 'react';
import { getCartResume } from './helpers';
import { useCheckout } from '../hooks/useCheckout';
import Button from './common/Button';
import { ShoppingCartIcon } from '@heroicons/react/outline';
import { formatPriceValue } from './helpers';

// eslint-disable-next-line react/prop-types
const CartButton = ({ goToCart }) => {
  const { cartItems } = useCheckout();
  const { total, count } = getCartResume(cartItems);

  return (
    <Button className="mr-4" onClick={goToCart}>
      <ShoppingCartIcon className="mr-3 -mr-1 h-5 w-5" aria-hidden={true} />
      {count} items {total > 0 ? `por ${formatPriceValue(total)}` : ''}
    </Button>
  );
};

export default CartButton;
