import React from 'react';

const useSearch = () => {
  const [isSearching, setSearching] = React.useState(false);
  const [text, setText] = React.useState();
  const onHandleSearch = ({ target }) => setText(target.value);
  const onCloseSearch = () => {
    setText('');
    setSearching(false);
  };

  return { text, isSearching, onHandleSearch, onCloseSearch, setSearching };
};
export default useSearch;
