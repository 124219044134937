import React from 'react';
import PropTypes from 'prop-types';
import Logo from './../Logo';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import { WhatsappIcon, FacebookIcon, InstagramIcon } from './../icons';

const Header = ({ store, isSearching }) => {
  const getIcon = (field) => {
    if (field === 'whatsapp') {
      return <WhatsappIcon className="h-4 w-4 hover:text-red-300" />;
    } else if (field === 'instagram') {
      return <InstagramIcon className="h-4 w-4" />;
    } else if (field === 'facebook') {
      return <FacebookIcon className="h-4 w-4" />;
    } else {
      return null;
    }
  };

  if (!store || isSearching) return null;

  return (
    <div className="flex flex-1 pt-8">
      <div className="lg:items-start lg:justify-start mr-4">
        {store.logo && <Logo logo={store.logo} />}
      </div>
      <div className="flex flex-col justify-center">
        <p className="text-gray-700 font-bold text-lg">{store.name}</p>
        <p className="text-gray-400">{store.shortDescription}</p>

        <div className="flex flex-row space-x-2 mt-2">
          {(store.social || []).map((social) => {
            return (
              <a
                key={social.field}
                href={social.value}
                target="_blank"
                rel="noopener noreferrer"
              >
                {getIcon(social.field)}
              </a>
            );
          })}
          {store.address && (
            <a
              href={`https://maps.google.com/?q=${store.address}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LocationMarkerIcon className="h-4 w-4" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  store: PropTypes.string,
  isSearching: PropTypes.bool,
  setSearching: PropTypes.func,
  onHandleSearch: PropTypes.func,
  searchText: PropTypes.string,
  onCloseSearch: PropTypes.func,
};

export default Header;
