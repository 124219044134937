import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SearchIcon, XIcon } from '@heroicons/react/outline';

const Searchbar = ({
  searchText,
  isSearching,
  setSearching,
  onHandleSearch,
  onCloseSearch,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-end w-full mt-4">
      <input
        value={searchText}
        onChange={onHandleSearch}
        placeholder={t('searchItemPlaceholder')}
        className={`bg-gray-100 p-4 m-4 text-gray-700 ${
          isSearching ? 'flex-1' : 'hidden'
        }`}
      />

      {isSearching ? (
        <button
          type="button"
          className="bg-white p-2 rounded-md text-gray-400"
          onClick={onCloseSearch}
        >
          <span className="sr-only">Quit searching</span>
          <XIcon
            className="w-7 h-7"
            aria-hidden="true"
            onClick={setSearching}
          />
        </button>
      ) : (
        <button
          type="button"
          className="bg-white p-2 rounded-md text-gray-400"
          onClick={() => setSearching(true)}
        >
          <span className="sr-only">Search</span>
          <SearchIcon
            className="w-6 h-6"
            aria-hidden="true"
            onClick={setSearching}
          />
        </button>
      )}
    </div>
  );
};

Searchbar.propTypes = {
  searchText: PropTypes.string,
  isSearching: PropTypes.bool,
  setSearching: PropTypes.func,
  onHandleSearch: PropTypes.func,
  onCloseSearch: PropTypes.func,
};

export default Searchbar;
