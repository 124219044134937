import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

const EmptyCategory = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col flex-1 items-center justify-center h-screen">
      <Image
        width={256}
        height={256}
        src={'/empty-category.png'}
        alt=""
        className="rounded-full"
      />
      <p className="text-gray-500">{t('emptyCategoryPlaceholder')}</p>
    </div>
  );
};

export default EmptyCategory;
