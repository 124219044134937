import React from 'react';
import PropTypes from 'prop-types';

const ItemWrapper = ({ children }) => {
  return (
    <div className="grid grid-cols-2 px-4 py-2 lg:mb-2 lg:p-4 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-14">
      {children}
    </div>
  );
};

ItemWrapper.propTypes = {
  children: PropTypes.object,
  onAddToCart: PropTypes.func,
};

export default ItemWrapper;
