import React from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line react/prop-types
const OutOfStockBadge = ({ visible }) => {
  const { t } = useTranslation();

  return visible ? (
    <span
      className="absolute my-2 mx-2 inline-flex items-center rounded-md bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10"
      style={{ top: 0, right: 0, zIndex: 1 }}
    >
      {t('outOfStock')}
    </span>
  ) : null;
};

export default OutOfStockBadge;
