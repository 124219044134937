import React from 'react';
import { PlusIcon } from '@heroicons/react/outline';
import { StarIcon } from '@heroicons/react/solid';
import Image from 'next/image';
import { formatPriceValue } from '../../helpers';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useStore from '../../../hooks/useStore';
import OutOfStockBadge from '../OutOfStockBadge';

const Item = ({ item, availabilityIndex, onItemClick, onItemAddClick }) => {
  const { t } = useTranslation();
  const { store } = useStore();

  const allowOnlineOrders = store?.catalog?.allowOnlineOrders;
  const { tracked, available } = getInventoryLevel(item.variations);
  const updatedAvailable = availabilityIndex[item.id]
    ? availabilityIndex[item.id].available
    : available;
  const isInStock = tracked ? updatedAvailable > 0 : true;
  const shouldDisplayAddButton = allowOnlineOrders && isInStock;

  return (
    <a key={item.id} onClick={() => onItemClick(item)} className="group">
      <div className="w-full relative flex justify-end items-end aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
        {item.isHighlighted && (
          <StarIcon
            className="w-4 h-4 m-2 text-amber-500 absolute"
            style={{ top: 0, right: 0, zIndex: 1 }}
          />
        )}
        <OutOfStockBadge visible={!isInStock} />
        <Image
          src={item.thumbnail || '/no-image.png'}
          alt={item.imageAlt}
          className="w-full h-full object-center object-cover group-hover:opacity-75"
          width={450}
          height={450}
        />

        {shouldDisplayAddButton && (
          <button
            type="button"
            className="p-2 rounded-3xl text-white bg-indigo-600 absolute"
            onClick={(e) => {
              e.stopPropagation();
              onItemAddClick(item);
            }}
          >
            <span className="sr-only">{t('cart.add')}</span>
            <PlusIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        )}
      </div>

      <h3 className="mt-6 text-sm text-gray-700">{item.name}</h3>
      <div className="flex items-end mt-1">
        <p className="text-lg font-medium text-gray-900">
          {formatPriceValue(item.price)}
        </p>
        {item.referencePrice && (
          <p className="ml-2 my-1 text-sm font-medium line-through text-gray-400">
            {formatPriceValue(item.referencePrice)}
          </p>
        )}
      </div>
    </a>
  );
};

const getInventoryLevel = (variations = {}) => {
  const atLeastOneIsTracked = Object.entries(variations).some(
    ([, variation]) => variation.trackQty,
  );
  const available = Object.entries(variations).reduce(
    (total, [, variation]) => total + variation.quantity,
    0,
  );

  return {
    tracked: atLeastOneIsTracked,
    available,
  };
};

Item.propTypes = {
  item: PropTypes.object,
  availabilityIndex: PropTypes.object,
  onItemClick: PropTypes.func,
  onItemAddClick: PropTypes.func,
};

export default Item;
