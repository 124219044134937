import { useState, useEffect, useMemo } from 'react';
import useCategory from './useCategory';
import { compareString } from '../components/helpers';

const useCatalog = ({
  data,
  textQuery,
  categoryId,
  showOutOfStockItems,
  availabilityIndex,
}) => {
  const [items, setItems] = useState([]);
  const [sort, setSort] = useState('newest');

  const filteredItems = useMemo(() => {
    return items.filter((item) => {
      return (
        compareString(item.name, textQuery || '') &&
        item.categoryId === (categoryId || item.categoryId) &&
        !getIsUnavailable(availabilityIndex, item.id) &&
        (showOutOfStockItems
          ? true
          : !getIsOutOfStock(availabilityIndex, item.id))
      );
    });
  }, [items, textQuery, categoryId, availabilityIndex]);

  const sortedItems = useMemo(() => {
    if (sort === 'price-desc') {
      return filteredItems.sort((a, b) => b.minPrice - a.minPrice);
    } else if (sort === 'price-asc') {
      return filteredItems.sort((a, b) => a.minPrice - b.minPrice);
    } else if (sort === 'highlighted') {
      return filteredItems.sort(
        (a, b) => (b.isHighlighted || null) - (a.isHighlighted || null),
      );
    } else {
      return filteredItems;
    }
  }, [filteredItems.length, sort]);

  const { categories, setCategories } = useCategory();

  const getCatalog = async function () {
    setItems(data?.products);
    setCategories([{ id: null, name: 'Todas' }, ...data.categories]);
  };

  useEffect(() => {
    getCatalog();
  }, []);

  const onSortChange = (sort) => setSort(sort);

  return { items: sortedItems, categories, onSortChange };
};

const getIsUnavailable = (availabilityIndex, itemId) => {
  const { isUnavailable } = availabilityIndex[itemId] || {};

  return isUnavailable;
};

const getIsOutOfStock = (availabilityIndex, itemId) => {
  const { isOutOfStock, available } = availabilityIndex[itemId] || {};
  const hasLeftUnits = available > 0;

  return isOutOfStock && !hasLeftUnits;
};

export default useCatalog;
