import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import VariationSelect from './VariationSelect';
import { formatPriceValue, getDiscountRate } from '../../helpers';
import QuantityInput from '../../common/QuantityInput';
import useStore from '../../../hooks/useStore';
import OutOfStockBadge from '../OutOfStockBadge';

const ItemSelection = ({ product, availabilityIndex, add }) => {
  const { t } = useTranslation();
  const variations = product.variations;
  const [selectedVariation, setSelectedVariation] = useState(variations[0]);
  const discountRate = getDiscountRate(selectedVariation);
  const [isExpanded, setExpanded] = useState();
  const [quantity, setQuantity] = useState(1);
  const isFractionable = product.format === 'fraction';
  const { store } = useStore();

  const { available, tracked } = getInventoryLevel(selectedVariation);
  const allowOnlineOrders = store?.catalog?.allowOnlineOrders;
  const updatedAvailable = availabilityIndex[product.id]
    ? availabilityIndex[product.id].available
    : available;
  const isInStock = tracked ? updatedAvailable > 0 : true;
  const shouldDisplayAddButton = allowOnlineOrders && isInStock;

  return (
    <div className="w-full grid grid-cols-1 gap-x-6 items-start sm:grid-cols-12 lg:items-center lg:gap-x-8">
      <div className="relative aspect-w-2 aspect-h-3 rounded-lg bg-gray-100 overflow-hidden sm:col-span-4 lg:col-span-5">
        <OutOfStockBadge visible={!isInStock} />
        <Image
          src={selectedVariation.thumbnail || '/no-image.png'}
          alt={product.imageAlt}
          className="object-center object-cover"
          width={600}
          height={600}
        />
      </div>
      <div className="sm:col-span-8 lg:col-span-7">
        {selectedVariation.referencePrice && (
          <div className="inline-block bg-red-100 px-2 rounded-lg mb-1">
            <p className="text-red-700 text-sm font-medium">{discountRate}</p>
          </div>
        )}
        <h2 className="text-sm font-small text-gray-500 sm:pr-12">
          {product.categoryName}
        </h2>
        <h2 className="text-xl font-medium text-gray-900 sm:pr-12">
          {product.name}
        </h2>

        {product.description && (
          <div>
            <p
              className={`${
                !isExpanded &&
                (product.description.length > 180 ? 'line-clamp-3' : '')
              } text-gray-500 mt-2`}
              style={{ whiteSpace: 'pre-line' }}
            >
              {product.description}
            </p>
            <div className="flex justify-end">
              {product.description.length > 180 && (
                <button
                  className="text-indigo-700 font-medium align-self-end"
                  onClick={() => setExpanded((isExpanded) => !isExpanded)}
                >
                  {t(`descriptionText.${isExpanded ? 'shrink' : 'expand'}`)}
                </button>
              )}
            </div>
          </div>
        )}

        <section aria-labelledby="information-heading" className="mt-1">
          <h3 id="information-heading" className="sr-only">
            Product information
          </h3>

          <div className="flex items-end mt-1">
            <p className="text-lg font-medium text-gray-900">
              {formatPriceValue(selectedVariation.price)}
            </p>
            {selectedVariation.referencePrice && (
              <p className="ml-2 my-1 text-sm font-medium line-through text-gray-400">
                {formatPriceValue(selectedVariation.referencePrice)}
              </p>
            )}
          </div>
        </section>

        <section aria-labelledby="options-heading" className="mt-8">
          <h3 id="options-heading" className="sr-only">
            Product options
          </h3>
          {variations.length > 1 && (
            <VariationSelect
              selectedVariation={selectedVariation}
              setSelectedVariation={setSelectedVariation}
              variations={product.variations}
            />
          )}
          {shouldDisplayAddButton && (
            <div className="flex items-center mt-2">
              <QuantityInput
                value={quantity}
                isFractionable={isFractionable}
                onHandleChange={setQuantity}
              />
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  const payload = {
                    id: selectedVariation.id,
                    variationId: selectedVariation.id,
                    productId: product.id,
                    name: `${product.name}${
                      selectedVariation?.name
                        ? ' ' + selectedVariation.name
                        : ''
                    }`,
                    format: product.format,
                    price: selectedVariation.price,
                    trackQty: Boolean(selectedVariation.trackQty),
                    ...(selectedVariation.thumbnail
                      ? { thumbnail: selectedVariation.thumbnail }
                      : {}),
                    ...(selectedVariation.costPrice
                      ? {
                          costPrice: selectedVariation.costPrice,
                        }
                      : {}),
                    quantity,
                  };
                  add(payload);
                }}
                className="w-full ml-2 bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {t('cart.add')}
              </button>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

const getInventoryLevel = (variation = {}) => {
  return {
    tracked: variation.trackQty,
    available: variation.quantity,
  };
};

ItemSelection.propTypes = {
  availabilityIndex: PropTypes.object,
  product: PropTypes.object,
  add: PropTypes.func,
};

export default ItemSelection;
